@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: "Nunito", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.loader {
  background: linear-gradient(
    90deg,
    rgba(190, 190, 190, 0.2) 25%,
    rgba(129, 129, 129, 0.24) 37%,
    rgba(190, 190, 190, 0.2) 63%
  );
  background-size: 400% 100%;
  -webkit-animation: skeleton-loading 1.4s ease infinite;
  animation: skeleton-loading 1.4s ease infinite;
}
.loader.rounded {
  border-radius: 30px !important;
}

.date {
  position: relative;
  z-index: 1;
  overflow: hidden;
  text-align: center;
  font-family: "Nunito", sans-serif;
}
.ant-picker-input{
  font-family: "Nunito", sans-serif;

}
.date:before,
.date:after {
  position: absolute;
  top: 50%;
  overflow: hidden;
  width: 49%;
  height: 1px;
  content: "\a0";
  background-color: #d1d5db;
}
.date:before {
  margin-left: -50%;
  text-align: right;
}

.date:after {
  margin-left: 1%;
  text-align: right;
}

.started {
  color: #f8c947;
}

.in-progress {
  color: #e97e00;
}
.nunito-semibold-2xl{
  @apply font-Nunito font-semibold text-2xl
}

.completed {
  color: #68b415;
}

.feedback {
  color: #eb5757;
}

.card-height {
  min-height: 5rem;
  max-height: 10rem;
}

.connector {
  position: relative;
}

.connector::after {
  content: "";
  display: block;
  height: 2rem;
  border-left: 1px solid rgb(var(--blue));
  z-index: -1;
  margin-left: 2rem;
}

@keyframes skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
/* .weekPicker
.ant-calendar-week-number-header
.ant-calendar-column-header-inner {
display: block !important;
visibility: hidden;
}

.weekPicker
.ant-calendar-week-number-header
.ant-calendar-column-header-inner::after {
visibility: visible;
content: "Wn";
} */
.weekPicker .ant-picker-content th:first-child{
  display: none;
}
.weekPicker .ant-picker-cell-week{
  display: none;
}

.finishModal .ant-modal-content {
  border-radius: 8px;
}
img.ant-image-preview-img {
    display: inline-block;
}
.max-w-85{
  max-width:85%;
}
.paymentModel .ant-modal-content{
  box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
}
.ant-popover{
  /* box-shadow:var(--stickyBottomShadow) */
}

.cardTooltip .ant-tooltip-content{
  border-radius: 8px;
  overflow: hidden;
}